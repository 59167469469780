import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

//TODO: fix hardcoded values (currently referencing hardcoded column width in DataGrid)
const SkeletonRow = () => {
  return (
    <Stack
      direction="row"
      sx={{
        width: 'min-content',
      }}
    >
      <Skeleton variant="rectangular" sx={{ my: 2, mx: 2 }} width={220 - 32} />
      <Skeleton variant="rectangular" sx={{ my: 2, mx: 2 }} width={300 - 32} />
      <Skeleton variant="rectangular" sx={{ my: 2, mx: 2 }} width={250 - 32} />
      <Skeleton variant="rectangular" sx={{ my: 2, mx: 2 }} width={214 - 32} />
      <Skeleton variant="rectangular" sx={{ my: 2, mx: 2 }} width={250 - 32} />
      <Skeleton variant="rectangular" sx={{ my: 2, mx: 2 }} width={200 - 32} />
    </Stack>
  );
};

const DataGridSkeleton = () => {
  return (
    <Box
      sx={{
        height: 500,
      }}
    >
      {[...Array(10)].map((_, index) => (
        <SkeletonRow key={index} />
      ))}
    </Box>
  );
};

export default DataGridSkeleton;
