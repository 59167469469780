import { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import Papa from 'papaparse';

const useReportData = ({
  transactions,
  expenseCategories,
  incomeCategories,
  categoryIdMap,
  startDate,
  endDate,
}) => {
  const [reportsData, setReportsData] = useState({
    income: 0,
    costOfGoodsSold: 0,
    operatingExpenses: 0,
    netProfit: 0,
    incomeData: [],
    costOfGoodsSoldData: [],
    operatingExpensesData: [],
    totalIncome: 0,
    totalCostOfGoodsSold: 0,
    totalOperatingExpenses: 0,
    grossProfit: 0,
  });

  const generateReport = useCallback(() => {
    const filteredTransactions = transactions.filter((t) => {
      const date = dayjs(t.authorized_date);
      return (
        date.isSame(startDate, 'day') ||
        date.isSame(endDate, 'day') ||
        (date.isAfter(startDate) && date.isBefore(endDate))
      );
    });

    const incomeTypes = incomeCategories.reduce(
      (a, c) => (c.tag ? [...a, c.value] : a),
      []
    );
    const {
      COST_OF_GOODS: costOfGoodsSoldTypes,
      OPERATING_EXPENSES: operatingExpensesTypes,
    } = expenseCategories.reduce((a, c) => {
      if (c.tag) {
        return {
          ...a,
          [c.tag]: a[c.tag] ? [...a[c.tag], c.value] : [c.value],
        };
      }
      return a;
    }, {});

    const {
      income,
      costOfGoodsSold,
      operatingExpenses,
      ...individualCategoryData
    } = filteredTransactions.reduce(
      (a, c) => {
        const category = c.category[0];
        if (incomeTypes.includes(category)) {
          return {
            ...a,
            [category]: a[category]
              ? a[category] + Number(c.amount)
              : Number(c.amount),
            income: a.income + Number(c.amount),
          };
        }

        if (costOfGoodsSoldTypes.includes(category)) {
          return {
            ...a,
            [category]: a[category]
              ? a[category] + Number(c.amount)
              : Number(c.amount),
            costOfGoodsSold: a.costOfGoodsSold + Number(c.amount),
          };
        }

        if (operatingExpensesTypes.includes(category)) {
          return {
            ...a,
            [category]: a[category]
              ? a[category] + Number(c.amount)
              : Number(c.amount),
            operatingExpenses: a.operatingExpenses + Number(c.amount),
          };
        }

        return a;
      },
      {
        income: 0,
        costOfGoodsSold: 0,
        operatingExpenses: 0,
      }
    );

    const netProfit =
      income - Math.abs(costOfGoodsSold) - Math.abs(operatingExpenses);

    const getIndividualCategoryData = (x) =>
      x.map((type) => {
        const value = individualCategoryData[type] || 0;
        return {
          label: categoryIdMap[type].label,
          value: `$${Math.abs(value).toFixed(2)}`,
          amount: Math.abs(value),
        };
      });

    const getTotal = (data) =>
      data.reduce((total, item) => total + Number(item.amount), 0).toFixed(2);

    const incomeData = getIndividualCategoryData(incomeTypes);
    const costOfGoodsSoldData = getIndividualCategoryData(costOfGoodsSoldTypes);
    const operatingExpensesData = getIndividualCategoryData(
      operatingExpensesTypes
    );

    const totalIncome = getTotal(incomeData);
    const totalCostOfGoodsSold = getTotal(costOfGoodsSoldData);
    const totalOperatingExpenses = getTotal(operatingExpensesData);
    const grossProfit = (totalIncome - totalCostOfGoodsSold).toFixed(2);

    setReportsData({
      income,
      costOfGoodsSold,
      operatingExpenses,
      netProfit,
      incomeData,
      costOfGoodsSoldData,
      operatingExpensesData,
      totalIncome,
      totalCostOfGoodsSold,
      totalOperatingExpenses,
      grossProfit,
    });
  }, [
    transactions,
    expenseCategories,
    incomeCategories,
    categoryIdMap,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (
      transactions.length > 0 &&
      expenseCategories.length > 0 &&
      incomeCategories.length > 0
    ) {
      generateReport();
    }
  }, [
    transactions,
    categoryIdMap,
    expenseCategories,
    incomeCategories,
    startDate,
    endDate,
    generateReport,
  ]);

  const handleExport = () => {
    const csvData = [
      [
        'Accounts',
        `${dayjs(startDate).format('MM-DD-YYYY')} to ${dayjs(endDate).format('MM-DD-YYYY')}`,
      ],
      ['Income', 'Amount'],
      ...reportsData.incomeData.map((item) => [item.label, item.value]),
      ['', ''],
      ['Total Income', `$${reportsData.totalIncome}`],
      ['', ''],
      ['Cost of Goods', 'Amount'],
      ...reportsData.costOfGoodsSoldData.map((item) => [
        item.label,
        item.value,
      ]),
      ['Total Cost of Goods Sold', `$${reportsData.totalCostOfGoodsSold}`],
      ['', ''],
      ['Gross Profit', `$${reportsData.grossProfit}`],
      ['', ''],
      ['Operating Expenses', 'Amount'],
      ...reportsData.operatingExpensesData.map((item) => [
        item.label,
        item.value,
      ]),
      ['', ''],
      ['Total Operating Expenses', `$${reportsData.totalOperatingExpenses}`],
      ['Net Profit', `$${reportsData.netProfit.toFixed(2)}`],
    ];

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute(
      'download',
      `Report : ${dayjs(startDate).format('MM-DD-YYYY')} to ${dayjs(endDate).format('MM-DD-YYYY')}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return {
    reportsData,
    handleExport,
  };
};

export default useReportData;
