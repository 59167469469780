import { CircularProgress, Tooltip } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import dayjs from 'dayjs';
import ReportFilter from './ReportFilter';
import {
  Container,
  ContainerBody,
  ContainerHeader,
  FlexItem,
  Title,
  Text,
  ExportButton,
} from './styled';
import FinancialStatement from './FinancialStatement';
import useTransactions from '../../common/hooks/useTransactions';
import useCategories from '../../common/hooks/useCategories';
import useDateRange from '../../common/hooks/useDateRange';
import useReportData from '../../common/hooks/useReportData';
import utc from 'dayjs/plugin/utc';
import { useDashboardContext } from '../../common/contexts/DashboardContext.js';

dayjs.extend(utc);

const ReportsPage = () => {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    minDateRangeLocal,
    maxDateRangeLocal,
  } = useDashboardContext();
  const { transactions, loadingData: loadingTransactions } = useTransactions();

  const {
    expenseCategories,
    incomeCategories,
    categoryIdMap,
    loadingCategories,
  } = useCategories();

  const { formatDate } = useDateRange(minDateRangeLocal, maxDateRangeLocal);

  const { reportsData, handleExport } = useReportData({
    transactions,
    expenseCategories,
    incomeCategories,
    categoryIdMap,
    startDate: dayjs.utc(startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
    endDate: dayjs.utc(endDate).format('YYYY-MM-DDTHH:mm:ssZ'),
  });

  const handleReset = () => {
    setStartDate(minDateRangeLocal);
    setEndDate(dayjs(maxDateRangeLocal).endOf('day').toDate());
  };

  if (
    startDate === null ||
    endDate === null ||
    loadingTransactions ||
    loadingCategories
  ) {
    return <Spinner />;
  }

  const {
    income,
    costOfGoodsSold,
    operatingExpenses,
    netProfit,
    incomeData,
    costOfGoodsSoldData,
    operatingExpensesData,
    totalIncome,
    totalCostOfGoodsSold,
    totalOperatingExpenses,
    grossProfit,
  } = reportsData;

  return (
    <>
      <Container>
        <ContainerHeader>
          <FlexItem>
            <Title>Profit &amp; Loss</Title>
          </FlexItem>
          <FlexItem justify="end">
            <Tooltip title="Export CSV Report" placement="top" arrow>
              <ExportButton
                variant="contained"
                color="primary"
                onClick={handleExport}
              >
                <ShareIcon /> Export
              </ExportButton>
            </Tooltip>
          </FlexItem>
        </ContainerHeader>
        <ContainerBody>
          <ReportFilter
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            income={income}
            costOfGoodsSold={costOfGoodsSold}
            operatingExpenses={operatingExpenses}
            netProfit={netProfit}
            minDateRangeLocal={minDateRangeLocal}
            maxDateRangeLocal={maxDateRangeLocal}
            handleReset={handleReset}
            isResetDisabled={(() => {
              const isDisabled =
                dayjs(startDate).isSame(dayjs(minDateRangeLocal), 'day') &&
                dayjs(endDate).isSame(dayjs(maxDateRangeLocal), 'day');
              return isDisabled;
            })()}
          />
        </ContainerBody>
      </Container>

      <Container>
        <ContainerHeader>
          <FlexItem>
            <Title>Accounts</Title>
          </FlexItem>
          <FlexItem dir="column">
            <Text>{formatDate(startDate)}</Text>
            <Text>to {formatDate(endDate)}</Text>
          </FlexItem>
        </ContainerHeader>
        <ContainerBody padding="0px">
          <FinancialStatement
            incomeData={incomeData}
            costOfGoodsSoldData={costOfGoodsSoldData}
            operatingExpensesData={operatingExpensesData}
            totalIncome={'$' + totalIncome}
            totalCostOfGoodsSold={'$' + totalCostOfGoodsSold}
            grossProfit={'$' + grossProfit}
            grossProfitPercentage={
              parseInt(totalIncome) === 0
                ? 'N/A '
                : ((grossProfit / totalIncome) * 100).toFixed(2) + '%'
            }
            totalOperatingExpenses={'$' + totalOperatingExpenses}
            netProfit={'$' + netProfit.toFixed(2)}
            netProfitPercentage={
              parseInt(totalIncome) === 0
                ? 'N/A'
                : ((netProfit / totalIncome) * 100).toFixed(2) + '%'
            }
          />
        </ContainerBody>
      </Container>
    </>
  );
};

export default ReportsPage;

const Spinner = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100vw',
      height: 'calc(100vh - 100px)',
    }}
  >
    <CircularProgress />
  </div>
);
