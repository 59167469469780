import React, { createContext, useContext, useState, useMemo } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import useDateRange from '../../common/hooks/useDateRange';

dayjs.extend(utc);
dayjs.extend(timezone);

const DashboardContext = createContext();

export const useDashboardContext = () => useContext(DashboardContext);

export const DashboardProvider = ({
  children,
  minDateRange,
  maxDateRange,
  reloadTransactions,
}) => {
  const { startDate, endDate, setStartDate, setEndDate } = useDateRange(
    minDateRange,
    maxDateRange
  );

  const minDateRangeLocal = useMemo(
    () => dayjs.utc(minDateRange).local().toDate(),
    [minDateRange]
  );
  const maxDateRangeLocal = useMemo(
    () => dayjs.utc(maxDateRange).local().toDate(),
    [maxDateRange]
  );

  const [activeTimeTab, setActiveTimeTab] = useState('all');
  const [toggleValue, setToggleValue] = useState('All');
  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedQuarters, setSelectedQuarters] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);

  return (
    <DashboardContext.Provider
      value={{
        activeTimeTab,
        setActiveTimeTab,
        toggleValue,
        setToggleValue,
        selectedWeeks,
        setSelectedWeeks,
        selectedMonths,
        setSelectedMonths,
        selectedQuarters,
        setSelectedQuarters,
        selectedYears,
        setSelectedYears,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        reloadTransactions,
        minDateRangeLocal,
        maxDateRangeLocal,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
