import React from 'react';
import FullFeaturedCrudGrid from '../../../components/features/Data Grid';
import { useOutletContext } from 'react-router-dom';
import { useDashboardContext } from '../../../components/common/contexts/DashboardContext';

const DataGridPage = () => {
  const {
    rows,
    setRows,
    accounts,
    loadingData,
    setLoadingData,
    setOpenAlert,
    setAlertSeverity,
    setAlertMessage,
    handleFilterChange,
    categories,
    setIsEditing,
  } = useOutletContext();

  const {
    activeTimeTab,
    selectedWeeks,
    selectedMonths,
    selectedQuarters,
    selectedYears,
    startDate,
    endDate,
    reloadTransactions,
  } = useDashboardContext();

  return (
    <FullFeaturedCrudGrid
      rows={loadingData ? [] : rows}
      accounts={accounts}
      loadingData={loadingData}
      setLoadingData={setLoadingData}
      timePeriod={activeTimeTab}
      setRows={setRows}
      setOpenAlert={setOpenAlert}
      setAlertSeverity={setAlertSeverity}
      setAlertMessage={setAlertMessage}
      reloadTransactions={reloadTransactions}
      onFilterChange={handleFilterChange}
      categories={categories}
      setIsEditing={setIsEditing}
      selectedWeeks={selectedWeeks}
      selectedMonths={selectedMonths}
      selectedQuarters={selectedQuarters}
      selectedYears={selectedYears}
      startDate={startDate}
      endDate={endDate}
    />
  );
};

export default DataGridPage;
