import React from 'react';
import MyPieChart from './Expenses Pie Chart';
import NetChart from './Net Profit Chart';
import TransactionChart from './Sales Area Chart';
import { Box, Typography } from '@mui/material';
//email: test.transactions@gmail.com
//password: test.transactions

const Charts = ({
  transactions,
  activeTimeTab,
  selectedWeeks,
  selectedMonths,
  selectedQuarters,
  selectedYears,
  startDate,
  endDate,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: {
          xs: '1450px',
          sm: '1450px',
          md: '1450px',
          lg: '850px',
          xl: '850px',
        },
        width: '80vw',
        position: 'relative',
        transformOrigin: 'top center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'column',
            lg: 'row',
            xl: 'row',
          },
          gap: 2,
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            flex: {
              xs: '0 1 80%',
              sm: '0 1 80%',
              md: '0 1 80%',
              lg: '1 1 5%',
              xl: '1 1 5%',
            },
          }}
        >
          {/* Sales Box */}
          <Box
            sx={{
              backgroundColor: 'white',
              padding: 2,
              borderRadius: 2,
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              mb: {
                xs: 2,
                sm: 2,
                md: 2,
                lg: 0,
                xl: 0,
              },
            }}
          >
            <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>
              Sales
            </Typography>
            <TransactionChart
              transactions={transactions}
              timePeriod={activeTimeTab}
              selectedWeeks={selectedWeeks}
              selectedMonths={selectedMonths}
              selectedQuarters={selectedQuarters}
              selectedYears={selectedYears}
              startDate={startDate}
              endDate={endDate}
            />
          </Box>

          {/* Expenses Box */}
          <Box
            sx={{
              backgroundColor: 'white',
              padding: 2,
              borderRadius: 2,
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              mb: {
                xs: 2,
                sm: 2,
                md: 2,
                lg: 0,
                xl: 0,
              },
            }}
          >
            <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>
              Expenses
            </Typography>
            <MyPieChart
              transactions={transactions}
              timePeriod={activeTimeTab}
              selectedWeeks={selectedWeeks}
              selectedMonths={selectedMonths}
              selectedQuarters={selectedQuarters}
              selectedYears={selectedYears}
              startDate={startDate}
              endDate={endDate}
            />
          </Box>
        </Box>

        {/* Net Profit/Loss Box */}
        <Box
          sx={{
            backgroundColor: 'white',
            padding: 2,
            borderRadius: 2,
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            flex: {
              xs: '0 1 80%',
              sm: '0 1 80%',
              md: '0 1 80%',
              lg: '1 1 35%',
              xl: '1 1 35%',
            },
            mt: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 0,
              xl: 0,
            },
            maxWidth: {
              lg: '60%',
              xl: '60%',
            },
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              textAlign: 'center',
              mb: {
                xs: '0',
                sm: '0',
                md: '0',
                lg: '60px',
                xl: '60px',
              },
            }}
          >
            Net Profit/Loss
          </Typography>
          <NetChart
            transactions={transactions}
            timePeriod={activeTimeTab}
            selectedWeeks={selectedWeeks}
            selectedMonths={selectedMonths}
            selectedQuarters={selectedQuarters}
            selectedYears={selectedYears}
            startDate={startDate}
            endDate={endDate}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Charts;
