import React from 'react';
import DateRangeComponent from './DateRangeComponent.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { IndicatorBox } from '../../styles/style.js';
import { useMediaQuery } from '@mui/material';

dayjs.extend(utc);
dayjs.extend(timezone);

const DateRangeIndicator = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  minDateRangeLocal,
  maxDateRangeLocal,
  handleReset,
}) => {
  const isBetween900And970 = useMediaQuery(
    '(min-width:900px) and (max-width:970px)'
  );

  return (
    <IndicatorBox
      borderRadiusProp={isBetween900And970 ? '18px' : '50px'}
      sx={{
        maxWidth: '400px',
        marginBottom: '10px',
        paddingLeft: '10px',
        paddingRight: '5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: isBetween900And970 ? 'center' : 'flex-start',
      }}
    >
      <DateRangeComponent
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        minDateRange={minDateRangeLocal}
        maxDateRange={maxDateRangeLocal}
        handleReset={handleReset}
        isResetDisabled={
          dayjs(startDate).isSame(dayjs(minDateRangeLocal), 'day') &&
          dayjs(endDate).isSame(dayjs(maxDateRangeLocal), 'day')
        }
      />
    </IndicatorBox>
  );
};

export default React.memo(DateRangeIndicator);
