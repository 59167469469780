import { useEffect, useState } from 'react';
import { fetchCategories } from '../../services/api/transactions-service';

const useCategories = () => {
  const [categories, setCategories] = useState({
    allCategories: [],
    expenseCategories: [],
    incomeCategories: [],
    categoryIdMap: {},
    loadingCategories: true,
  });

  useEffect(() => {
    fetchCategories().then((response) =>
      setCategories({
        allCategories: response?.data?.categories || [],
        expenseCategories: response?.data?.expenseCategories || [],
        incomeCategories: response?.data?.incomeCategories || [],
        categoryIdMap: response?.data?.categories?.reduce((acc, category) => {
          acc[category.value] = category;
          return acc;
        }, {}),
        loadingCategories: false,
      })
    );
  }, []);

  return categories;
};

export default useCategories;
