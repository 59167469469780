import React, { useState, useRef, useEffect } from 'react';
import LinkAccountsPage from './LinkAccountsPage';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../api/axiosWrapper';

const LinkBankAccountPage = () => {
  const apiRef = useRef(null);
  const [loadingData, setLoadingData] = useState(false);
  const [linkToken, setLinkToken] = useState(null);
  const navigate = useNavigate();

  const fetchLinkToken = async () => {
    try {
      const { data } = await axiosInstance.post('/createPlaidLinkToken');
      if (data.success) {
        setLinkToken(data.data.linkToken.link_token);
        localStorage.setItem('link_token', data.data.linkToken.link_token);
      } else {
        console.error('Failed to create Plaid link token');
      }
    } catch (error) {
      console.error('Error fetching link token:', error);
    }
  };

  useEffect(() => {
    const storedLinkToken = localStorage.getItem('link_token');
    if (storedLinkToken) {
      setLinkToken(storedLinkToken);
    } else {
      fetchLinkToken();
    }
  }, []);

  const onSuccess = async (public_token) => {
    console.log('Link success:', public_token);
    navigate('/dashboard');
  };

  const onExit = (error, metadata) => {
    console.error('Link exited:', error, metadata);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="calc(100vh - 64px)"
    >
      {loadingData ? (
        <Box display="flex" flexDirection="column" alignItems="center">
          <CircularProgress size={60} />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Linking your bank account...
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            This may take a few moments. Please do not close the browser.
          </Typography>
        </Box>
      ) : (
        linkToken && (
          <LinkAccountsPage
            loadPlaidWidget={true}
            apiRef={apiRef}
            key={linkToken}
            linkToken={linkToken}
            loadingData={loadingData}
            setLoadingData={setLoadingData}
            onSuccess={onSuccess}
            onExit={onExit}
          />
        )
      )}
    </Box>
  );
};

export default LinkBankAccountPage;
