import styled from '@emotion/styled';
import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { colors } from '../../../themes/theme';
import { Tooltip } from '@mui/material';
import { ResetButton } from './styled';
import { addCommasToNumber } from './addCommasToNumber';

const ReportFilter = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  income,
  costOfGoodsSold,
  operatingExpenses,
  netProfit,
  minDateRangeLocal,
  maxDateRangeLocal,
  handleReset,
  isResetDisabled,
}) => {
  return (
    <Container>
      <Row>
        <Label htmlFor="date-range">Date Range</Label>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StyledDatePicker
            label=""
            inputFormat="MM-DD-YYYY"
            value={dayjs(startDate)}
            minDate={dayjs(minDateRangeLocal)}
            maxDate={dayjs(endDate)}
            onChange={(newDate) => setStartDate(newDate.toDate())}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <span>to</span>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StyledDatePicker
            label=""
            inputFormat="MM-DD-YYYY"
            value={dayjs(endDate)}
            minDate={dayjs(startDate)}
            maxDate={dayjs(maxDateRangeLocal)}
            onChange={(newDate) => setEndDate(newDate.toDate())}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <Tooltip title="Reset Date Range" placement="right" arrow>
          <ResetButton
            disabled={isResetDisabled}
            variant="contained"
            color="primary"
            onClick={handleReset}
            sx={{ marginLeft: '10px' }}
          >
            Reset
          </ResetButton>
        </Tooltip>
      </Row>
      <CalculationRow>
        <CalculationItem>
          <span>${addCommasToNumber(Math.abs(income?.toFixed?.(2)))}</span>
          <small>Income</small>
        </CalculationItem>
        <span>-</span>
        <CalculationItem>
          <span>
            $
            {costOfGoodsSold !== 0
              ? addCommasToNumber(Math.abs(costOfGoodsSold?.toFixed?.(2)))
              : addCommasToNumber(costOfGoodsSold?.toFixed?.(2))}
          </span>
          <small>Cost of Goods Sold</small>
        </CalculationItem>
        <span>-</span>
        <CalculationItem>
          <span>
            $
            {operatingExpenses !== 0
              ? addCommasToNumber(Math.abs(operatingExpenses?.toFixed?.(2)))
              : addCommasToNumber(operatingExpenses?.toFixed?.(2))}
          </span>
          <small>Operating Expenses</small>
        </CalculationItem>
        <span>=</span>
        <CalculationItem color={netProfit < 0 ? 'red' : 'green'}>
          <span>
            {netProfit < 0 ? '-$' : '$'}
            {addCommasToNumber(Math.abs(netProfit)?.toFixed?.(2))}
          </span>
          <small>Net {netProfit < 0 ? 'Loss' : 'Profit'}</small>
        </CalculationItem>
      </CalculationRow>
    </Container>
  );
};

export default ReportFilter;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 5px;
  width: 100%;
  padding: 20px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 auto;

  @media (max-width: 765px) {
    flex-direction: column;
    align-items: start;
    margin-bottom: 12px;
  }
`;

const Label = styled.label`
  font-weight: bold;
  width: 100px;
`;

const CalculationRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 10px;
  font-size: 1.2em;
  margin-top: 20px;
  border: 1px solid ${colors.gray};
  padding: 20px 40px;
  border-radius: 20px;
  width: fit-content;
  margin: 0 auto;

  > span {
    font-size: 1.5em;
  }

  @media (max-width: 765px) {
    flex-direction: column;
    align-items: start;
    gap: 0px;
  }
`;

const CalculationItem = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.color || 'black'};
  font-weight: bold;

  small {
    width: 100%;
    font-size: 0.4em;
    color: ${colors.primary};
    text-align: left;
    font-weight: normal;
  }

  @media (min-width: 768px) {
    font-size: 1em !important;
  }

  @media (min-width: 1024px) {
    font-size: 1.5em !important;
  }

  @media (min-width: 1440px) {
    font-size: 1.8em !important;
  }

  @media (min-width: 2048px) {
    font-size: 2em !important;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  & .MuiInputBase-root {
    padding: 4px 8px;
    width: 150px;
    & input {
      padding: 2px;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border-radius: 5px;
  }
  svg {
    padding: 2px;
  }
`;
