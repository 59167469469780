import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { Modal, Box, Typography, CircularProgress } from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { colors } from '../../../themes/theme';
import { GridDeleteIcon } from '@mui/x-data-grid';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import uploadService from '../../services/api/upload-service';
import dayjs from 'dayjs';

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBox = styled(Box)`
  position: relative;
  width: 480px;
  height: 560px;
  background-color: ${colors.white};
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.3s ease-in-out;
  z-index: 1200;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
  border-radius: 8px;
  margin: 24px;
  overflow: hidden;
  border: 1px solid ${colors.receiptButtonGrey};
`;

const ImgFlex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-direction: column;
  text-align: center;
  padding: 30px;
  color: ${colors.darkGray3};
`;

const StyledIconButton = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.disabled ? colors.receiptButtonGrey : props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  transition: all 0.2s ease;

  svg {
    fill: ${(props) => (props.disabled ? colors.darkGray2 : colors.white)};
    width: 24px;
    height: 24px;
  }

  &:hover {
    background-color: ${(props) =>
      props.disabled ? colors.receiptButtonGrey : props.hoverColor};
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-bottom: 24px;
`;

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  cursor: zoom-in;
  transition: all 0.3s ease-in-out;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background: ${colors.white};
  border: none;
  cursor: pointer;
  padding: 4px;
  color: ${colors.darkGray3};
  font-size: 24px;
  z-index: 1201;
  transition: color 0.2s ease;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  &:hover {
    color: ${colors.primary};
  }
`;

const IconWrapper = styled.div`
  background-color: ${colors.primary};
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 24px;

  svg {
    width: 40px;
    height: 40px;
    fill: ${colors.white};
  }
`;

const StyledTypography = styled(Typography)`
  margin-bottom: 8px;
`;

const ZoomedImageOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1400;
  cursor: zoom-out;
`;

const ZoomedImage = styled(StyledImage)`
  max-width: 90vw;
  max-height: 90vh;
  cursor: zoom-out;
`;

const ReceiptModal = ({
  transactionId,
  open,
  handleClose,
  receiptUrl,
  reloadTransactions,
  setAlertMessage,
  setAlertSeverity,
  setOpenAlert,
  transactionDescription,
  transactionDate,
}) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [zoomed, setZoomed] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setFileUrl(receiptUrl);
    return () => {
      setFileUrl(null);
    };
  }, [receiptUrl]);

  const createAlert = (severity, message) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setOpenAlert(true);
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 5 * 1024 * 1024) {
        createAlert(
          'warning',
          'File size exceeds 5MB. Please choose a smaller file.'
        );
        return;
      }

      setLoading(true);
      try {
        const response = await uploadService.uploadFile(
          transactionId,
          selectedFile
        );
        setFileUrl(response.data.fileUrl);
        reloadTransactions();
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        createAlert('success', 'Receipt uploaded successfully!');
      } catch (error) {
        console.error('Error uploading file:', error);
        createAlert('error', 'Failed to upload file. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDeleteReceipt = async () => {
    if (fileUrl) {
      try {
        await uploadService.deleteFile(transactionId);
        reloadTransactions();
        setFileUrl(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        createAlert('success', 'Receipt deleted successfully!');
      } catch (error) {
        console.error('Error deleting file:', error);
        createAlert('error', 'Failed to delete file. Please try again.');
      }
    }
  };

  const handleDownload = async () => {
    if (fileUrl) {
      try {
        const image = await fetch(fileUrl);
        const imageBlob = await image.blob();
        const imageURL = URL.createObjectURL(imageBlob);

        const formattedDescription = transactionDescription
          .replace(/[^a-zA-Z0-9]/g, '_')
          .slice(0, 30);
        const formattedDate = dayjs(transactionDate).format('MM-DD-YYYY');
        const fileName = `receipt_${formattedDescription}_${formattedDate}.jpg`;

        const link = document.createElement('a');
        link.href = imageURL;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(imageURL);
      } catch (error) {
        console.error('Error downloading file:', error);
        createAlert('error', 'Failed to download file. Please try again.');
      }
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile && droppedFile.type.startsWith('image/')) {
      setLoading(true);
      try {
        const response = await uploadService.uploadFile(
          transactionId,
          droppedFile
        );
        setFileUrl(response.data.fileUrl);
        reloadTransactions();
      } catch (error) {
        console.error('Error uploading file:', error);
        createAlert('error', 'Failed to upload file. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleZoomIn = () => setZoomed(true);
  const handleZoomOut = (e) => {
    e.stopPropagation();
    setZoomed(false);
  };

  return (
    <>
      <StyledModal
        open={open}
        onClose={handleClose}
        aria-labelledby="receipt-modal-title"
        aria-describedby="receipt-modal-description"
      >
        <StyledBox>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>

          <Container onDrop={handleDrop} onDragOver={handleDragOver}>
            {fileUrl ? (
              <StyledImage src={fileUrl} alt="Receipt" onClick={handleZoomIn} />
            ) : (
              <ImgFlex>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <IconWrapper>
                      <ReceiptLongIcon />
                    </IconWrapper>
                    <StyledTypography variant="h6" gutterBottom>
                      Upload a receipt to keep track of your expenses
                    </StyledTypography>
                    <Typography variant="body2" color={colors.darkGray2}>
                      Drag & drop an image file here or click upload below to
                      choose a file
                    </Typography>
                  </>
                )}
              </ImgFlex>
            )}
          </Container>
          <Flex>
            <StyledIconButton
              color={colors.primary}
              hoverColor={colors.primaryDark}
              onClick={handleUploadClick}
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                ref={fileInputRef}
              />
              <CloudUploadIcon />
            </StyledIconButton>
            <StyledIconButton
              color={colors.expenses}
              hoverColor={colors.expensesDark}
              onClick={handleDeleteReceipt}
              disabled={!fileUrl}
            >
              <GridDeleteIcon />
            </StyledIconButton>
            <StyledIconButton
              color={colors.primary}
              hoverColor={colors.primaryDark}
              onClick={handleDownload}
              disabled={!fileUrl}
            >
              <DownloadIcon />
            </StyledIconButton>
          </Flex>
        </StyledBox>
      </StyledModal>
      {zoomed && (
        <ZoomedImageOverlay onClick={handleZoomOut}>
          <ZoomedImage
            src={fileUrl}
            alt="Zoomed Receipt"
            onClick={handleZoomOut}
          />
        </ZoomedImageOverlay>
      )}
    </>
  );
};

export default ReceiptModal;
