import React from 'react';
import {
  SFormControl,
  SInputLabel,
  SSelect,
  SMenuItem,
} from '../../styles/style';

const TransactionFilter = ({ toggleValue, handleToggleChange }) => {
  return (
    <SFormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <SInputLabel id="toggle-select-small-label">Filters</SInputLabel>
      <SSelect
        labelId="toggle-select-small-label"
        id="toggle-select-small"
        value={toggleValue}
        label="Filters"
        onChange={handleToggleChange}
        color="primary"
      >
        <SMenuItem value="All">All</SMenuItem>
        <SMenuItem value="income">Income</SMenuItem>
        <SMenuItem value="expenses">Expenses</SMenuItem>
      </SSelect>
    </SFormControl>
  );
};

export default TransactionFilter;
