import React, { useState, useEffect, useCallback } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Sector,
  ResponsiveContainer,
  Text,
} from 'recharts';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { pieChart } from '../../../../themes/theme';
import { colors } from '../../../../themes/theme';

dayjs.extend(isBetween);

const { pieChartColors, primary, secondary } = pieChart;

const formatNumber = (num) => {
  if (num >= 1000000) {
    return `$${(num / 1000000).toFixed(1)}m`;
  } else if (num >= 1000) {
    return `$${(num / 1000).toFixed(1)}k`;
  } else if (num >= 100) {
    return `$${num.toFixed(0)}`;
  } else {
    return `$${num.toFixed(2)}`;
  }
};

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    value,
  } = props;

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fill={primary}
        style={{ fontSize: '30px', fontWeight: 'bold', textTransform: 'none' }}
      >
        {formatNumber(value)}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

const MyPieChart = ({
  transactions,
  timePeriod,
  selectedWeeks,
  selectedMonths,
  selectedQuarters,
  selectedYears,
  startDate,
  endDate,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState([]);

  const onPieEnter = useCallback((_, index) => {
    setActiveIndex(index);
  }, []);

  useEffect(() => {
    const filteredTransactions = transactions.filter((transaction) => {
      const transactionDate = dayjs(transaction.authorized_date).local();
      const transactionMonthStr = transactionDate.format('MMMM YYYY');
      const transactionQuarterStr = `Q${Math.floor(transactionDate.month() / 3) + 1} ${transactionDate.year()}`;
      const transactionYearStr = transactionDate.format('YYYY');

      switch (timePeriod) {
        case 'week':
          return selectedWeeks.some((week) => {
            const [start, end] = week.split(' - ');
            const year = end.split(', ')[1];
            const startDate = dayjs(`${start}, ${year}`).startOf('day');
            const endDate = dayjs(end).endOf('day');
            return transactionDate.isBetween(startDate, endDate, null, '[]');
          });
        case 'month':
          return selectedMonths.includes(transactionMonthStr);
        case 'quarter':
          return selectedQuarters.includes(transactionQuarterStr);
        case 'year':
          return selectedYears.includes(transactionYearStr);
        default:
          if (startDate && endDate) {
            const start = dayjs(startDate).startOf('day');
            const end = dayjs(endDate).endOf('day');
            return transactionDate.isBetween(start, end, null, '[]');
          }
          return true;
      }
    });

    const aggregatedData = filteredTransactions.reduce((acc, transaction) => {
      const rawCategory = transaction.category?.[0];
      const category = rawCategory.replace(/_/g, ' ');
      const amount = parseFloat(transaction.amount);
      if (!acc[category]) {
        acc[category] = { total: 0 };
      }
      if (amount < 0) {
        acc[category].total += Math.abs(amount);
      }
      return acc;
    }, {});

    const newData = Object.keys(aggregatedData)
      .map((category) => ({
        name: category,
        value: aggregatedData[category].total,
      }))
      .filter((entry) => entry.value !== 0);

    setData(newData);
    setActiveIndex(0);
  }, [
    transactions,
    timePeriod,
    selectedWeeks,
    selectedMonths,
    selectedQuarters,
    selectedYears,
    startDate,
    endDate,
  ]);

  const hasExpenses = data.some((t) => t.value);

  if (!hasExpenses || data.length === 0) {
    return (
      <ResponsiveContainer
        width="100%"
        height="200px"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text
          x={200}
          y={100}
          textAnchor="middle"
          verticalAnchor="middle"
          style={{ fontSize: '16px', color: colors.lightGray3 }}
        >
          No data available for this period
        </Text>
      </ResponsiveContainer>
    );
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={185}>
        <PieChart>
          <Pie
            data={data}
            innerRadius={60}
            outerRadius={80}
            labelLine={false}
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            onMouseEnter={onPieEnter}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={pieChartColors[index % pieChartColors.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div style={{ textAlign: 'center', marginTop: '5px' }}>
        <div
          style={{
            fontSize: '15px',
            fontWeight: 'bold',
            color: primary,
            textTransform: 'capitalize',
          }}
        >
          {data[activeIndex]?.name || ''}
        </div>
        <div style={{ fontSize: '15px', color: secondary }}>
          {data[activeIndex]
            ? `${((data[activeIndex].value / data.reduce((acc, entry) => acc + entry.value, 0)) * 100).toFixed(2)}%`
            : ''}
        </div>
      </div>
    </>
  );
};

export default MyPieChart;
