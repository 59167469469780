import React, { useMemo, useState, useCallback, useEffect } from 'react';
import {
  Box,
  Modal,
  Tab,
  MenuItem,
  IconButton,
  useMediaQuery,
  useTheme,
  Checkbox,
} from '@mui/material';
import dayjs from 'dayjs';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  CustomTabs,
  ValueTypography,
  ItemFieldset,
  ItemLegend,
  StyledIconButton,
  StyledSelect,
} from '../../styles/style.js';
import { colors } from '../../../themes/theme.js';
import { useFinancialMetrics } from '../../common/hooks/useFinancialMetrics.js';
import { useDashboardContext } from '../../common/contexts/DashboardContext.js';
import {
  generateWeekOptions,
  generateMonthOptions,
  generateQuarterOptions,
  generateYearOptions,
  handleWeekSelectionChange,
  handleMonthSelectionChange,
  handleQuarterSelectionChange,
  handleYearSelectionChange,
} from './dateOptions';
import DateRangeIndicator from './DateRangeIndicator';
import { useDateState } from '../../common/hooks/useDateState.js';
import TimeTabsModal from './TimeTabsModal';

const MetricsDisplay = React.memo(
  ({ transactions, onTimePeriodChange, filterState }) => {
    const {
      activeTimeTab,
      setActiveTimeTab,
      selectedWeeks,
      setSelectedWeeks,
      selectedMonths,
      setSelectedMonths,
      selectedQuarters,
      setSelectedQuarters,
      selectedYears,
      setSelectedYears,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      minDateRangeLocal,
      maxDateRangeLocal,
    } = useDashboardContext();

    const dateState = useDateState();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [hoveredTab, setHoveredTab] = useState(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isMdOrBelow = useMediaQuery(theme.breakpoints.down('md'));

    const filteredTransactions = useMemo(() => {
      if (filterState === 'income') {
        return transactions.filter((transaction) => transaction.amount >= 0);
      } else if (filterState === 'expenses') {
        return transactions.filter((transaction) => transaction.amount <= 0);
      }
      return transactions;
    }, [transactions, filterState]);

    const { income, expenses, netProfit } = useFinancialMetrics(
      filteredTransactions,
      activeTimeTab,
      selectedWeeks,
      selectedMonths,
      selectedQuarters,
      selectedYears,
      startDate,
      endDate
    );

    const items = useMemo(
      () => [
        { legend: 'Sales', value: income, color: colors.sales },
        { legend: 'Expenses', value: expenses, color: colors.expenses },
        {
          legend: `Net ${netProfit >= 0 || netProfit === '$0.00' ? 'Profit' : 'Loss'}`,
          value: netProfit,
          color:
            netProfit >= 0 || netProfit === '$0.00'
              ? colors.sales
              : colors.expenses,
        },
      ],
      [income, expenses, netProfit]
    );

    const weekOptions = useMemo(
      () => generateWeekOptions(filteredTransactions, startDate, endDate),
      [filteredTransactions, startDate, endDate]
    );
    const monthOptions = useMemo(
      () => generateMonthOptions(filteredTransactions, startDate, endDate),
      [filteredTransactions, startDate, endDate]
    );
    const quarterOptions = useMemo(
      () => generateQuarterOptions(filteredTransactions, startDate, endDate),
      [filteredTransactions, startDate, endDate]
    );
    const yearOptions = useMemo(
      () => generateYearOptions(filteredTransactions, startDate, endDate),
      [filteredTransactions, startDate, endDate]
    );

    const handleReset = useCallback(() => {
      setStartDate(dayjs(minDateRangeLocal).format('YYYY-MM-DD'));
      setEndDate(dayjs(maxDateRangeLocal).endOf('day').format('YYYY-MM-DD'));
    }, [minDateRangeLocal, maxDateRangeLocal, setStartDate, setEndDate]);

    useEffect(() => {
      if (minDateRangeLocal && maxDateRangeLocal) {
        handleReset();
      }
    }, [minDateRangeLocal, maxDateRangeLocal, handleReset]);

    const handleTimeTabChange = useCallback(
      (event, newValue) => {
        setActiveTimeTab(newValue);
        onTimePeriodChange(newValue);
      },
      [setActiveTimeTab, onTimePeriodChange]
    );

    const handlePrev = useCallback(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? items.length - 1 : prevIndex - 1
      );
    }, [items.length]);

    const handleNext = useCallback(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === items.length - 1 ? 0 : prevIndex + 1
      );
    }, [items.length]);

    const handleClick = useCallback(
      (event) => {
        const targetClassName = event.target.className;
        const isTargetBackdrop =
          typeof targetClassName === 'string' &&
          targetClassName.includes('MuiBackdrop-root') &&
          targetClassName.includes('MuiBackdrop-invisible') &&
          targetClassName.includes('MuiModal-backdrop');

        if (isTargetBackdrop) {
          dateState.handleCloseWeek();
          dateState.handleCloseMonth();
          dateState.handleCloseQuarter();
          dateState.handleCloseYear();
          event.stopPropagation();
        }
      },
      [dateState]
    );

    const openModal = useCallback(() => setModalOpen(true), []);
    const closeModal = useCallback(() => setModalOpen(false), []);

    const renderTabContent = useCallback(
      (label, isOpen, handleOver, selectedValues, handleChange, options) => (
        <Tab
          sx={{
            width: 'fit-content',
            minWidth: 'unset',
            '&.MuiTab-root': {
              padding: 0,
            },
          }}
          label={
            <StyledSelect
              sx={{
                zIndex: hoveredTab === label ? 1301 : 'auto', // Apparently the invisble backdrop of the MenuItems has z-index of 1300 which prevents the cursor style from applying.
                width: '100%',
                minWidth: '100%',
                maxWidth: '100%',
                padding: 0,
              }}
              open={isOpen}
              onMouseEnter={() => {
                setHoveredTab(label);
                handleOver();
              }}
              multiple
              value={selectedValues}
              onChange={handleChange}
              onClick={handleClick}
              renderValue={() => label}
              displayEmpty
              MenuProps={{
                PaperProps: {
                  onMouseLeave: () => {
                    dateState[`handleClose${label}`]();
                    setHoveredTab(null);
                  },
                  style: {
                    maxHeight: 200,
                    maxWidth: 300,
                    fontSize: '14px',
                  },
                },
                BackdropProps: {
                  invisible: true,
                },
                autoFocus: false,
              }}
            >
              {selectedValues.length !== options.length ? (
                <MenuItem value="select-all">
                  <em>Select All</em>
                </MenuItem>
              ) : (
                <MenuItem value="deselect-all">
                  <em>Deselect All</em>
                </MenuItem>
              )}
              {options.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox
                    sx={{ ml: -1 }}
                    checked={selectedValues.indexOf(option) > -1}
                  />
                  {option}
                </MenuItem>
              ))}
            </StyledSelect>
          }
          value={label.toLowerCase()}
        />
      ),
      [dateState, handleClick, hoveredTab]
    );

    return (
      <>
        <Box
          sx={{
            width: '80vw',
            height: 'fit-content',
            backgroundColor: 'white',
            mb: 0,
            pb: { xs: 4, sm: 5, md: 6 },
            borderRadius: 6,
            boxShadow: colors.shadow,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {!isMdOrBelow ? (
            <Box
              sx={{
                width: '100%',
                position: 'relative',
                bottom: '14px',
                left: '35px',
              }}
            >
              <CustomTabs
                value={activeTimeTab}
                onChange={handleTimeTabChange}
                aria-label="time tabs"
                scrollButtons="auto"
                sx={{
                  '& .MuiTab-root': { minWidth: 'fit-content' },
                  cursor: 'pointer',
                }}
              >
                {renderTabContent(
                  'Week',
                  dateState.isOpenWeek,
                  dateState.handleOverWeek,
                  selectedWeeks,
                  (event) =>
                    handleWeekSelectionChange(
                      event,
                      weekOptions,
                      setSelectedWeeks,
                      setSelectedMonths,
                      setSelectedQuarters,
                      setSelectedYears
                    ),
                  weekOptions
                )}
                {renderTabContent(
                  'Month',
                  dateState.isOpenMonth,
                  dateState.handleOverMonth,
                  selectedMonths,
                  (event) =>
                    handleMonthSelectionChange(
                      event,
                      monthOptions,
                      setSelectedWeeks,
                      setSelectedMonths,
                      setSelectedQuarters,
                      setSelectedYears
                    ),
                  monthOptions
                )}
                {renderTabContent(
                  'Quarter',
                  dateState.isOpenQuarter,
                  dateState.handleOverQuarter,
                  selectedQuarters,
                  (event) =>
                    handleQuarterSelectionChange(
                      event,
                      quarterOptions,
                      setSelectedWeeks,
                      setSelectedMonths,
                      setSelectedQuarters,
                      setSelectedYears
                    ),
                  quarterOptions
                )}
                {renderTabContent(
                  'Year',
                  dateState.isOpenYear,
                  dateState.handleOverYear,
                  selectedYears,
                  (event) =>
                    handleYearSelectionChange(
                      event,
                      yearOptions,
                      setSelectedWeeks,
                      setSelectedMonths,
                      setSelectedQuarters,
                      setSelectedYears
                    ),
                  yearOptions
                )}
                <Tab
                  label="All"
                  value="all"
                  style={{ fontSize: '14px', flexShrink: 0 }}
                />
                <DateRangeIndicator
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  minDateRangeLocal={minDateRangeLocal}
                  maxDateRangeLocal={maxDateRangeLocal}
                  handleReset={handleReset}
                  isResetDisabled={
                    dayjs(startDate).isSame(dayjs(minDateRangeLocal), 'day') &&
                    dayjs(endDate).isSame(dayjs(maxDateRangeLocal), 'day')
                  }
                />
              </CustomTabs>
            </Box>
          ) : (
            <Box sx={{ marginRight: '-15px', marginLeft: 'auto' }}>
              <IconButton
                onClick={openModal}
                sx={{
                  position: 'relative',
                  bottom: '14px',
                  backgroundColor: colors.primary,
                  color: 'white',
                  borderRadius: '50%',
                  '&:hover': { backgroundColor: colors.primaryHover },
                }}
              >
                <FilterListIcon />
              </IconButton>
            </Box>
          )}

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              mt: 1,
            }}
          >
            {isMobile && (
              <StyledIconButton
                onClick={handlePrev}
                visibility={currentIndex !== 0}
              >
                <ChevronLeftIcon />
              </StyledIconButton>
            )}

            {isMobile ? (
              <ItemFieldset>
                <ItemLegend>{items[currentIndex].legend}</ItemLegend>
                <ValueTypography color={items[currentIndex].color}>
                  {items[currentIndex].value}
                </ValueTypography>
              </ItemFieldset>
            ) : (
              items.map((item, index) => (
                <ItemFieldset key={index}>
                  <ItemLegend>{item.legend}</ItemLegend>
                  <ValueTypography color={item.color}>
                    {item.value}
                  </ValueTypography>
                </ItemFieldset>
              ))
            )}

            {isMobile && (
              <StyledIconButton
                onClick={handleNext}
                visibility={currentIndex !== items.length - 1}
              >
                <ChevronRightIcon />
              </StyledIconButton>
            )}
          </Box>
        </Box>

        <Modal open={modalOpen} onClose={closeModal}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: 'auto',
              marginTop: '50px',
            }}
          >
            <Box
              sx={{
                width: { xs: '90vw', sm: '80vw', md: '60vw' },
                maxHeight: '90vh',
                backgroundColor: 'white',
                borderRadius: 6,
                boxShadow: colors.shadow,
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
              }}
            >
              <TimeTabsModal
                onTimePeriodChange={onTimePeriodChange}
                setActiveTimeTab={setActiveTimeTab}
                minDateRangeLocal={minDateRangeLocal}
                isResetDisabled={
                  dayjs(startDate).isSame(dayjs(minDateRangeLocal), 'day') &&
                  dayjs(endDate).isSame(dayjs(maxDateRangeLocal), 'day')
                }
                maxDateRangeLocal={maxDateRangeLocal}
                handleReset={handleReset}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                selectedWeeks={selectedWeeks}
                setSelectedWeeks={setSelectedWeeks}
                weekOptions={weekOptions}
                selectedMonths={selectedMonths}
                setSelectedMonths={setSelectedMonths}
                monthOptions={monthOptions}
                selectedQuarters={selectedQuarters}
                setSelectedQuarters={setSelectedQuarters}
                quarterOptions={quarterOptions}
                selectedYears={selectedYears}
                setSelectedYears={setSelectedYears}
                yearOptions={yearOptions}
                closeModal={closeModal}
              />
            </Box>
          </Box>
        </Modal>
      </>
    );
  }
);

export default MetricsDisplay;
