import React, { useState, useEffect, useMemo } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceLine,
  Text,
} from 'recharts';
import { Box, Container, Paper, Typography } from '@mui/material';
import { styled } from '@mui/system';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { colors } from '../../../../themes/theme';
import { addCommasToNumber } from '../../Reports/addCommasToNumber';

dayjs.extend(isBetween);
dayjs.extend(quarterOfYear);

const ScrollContainer = styled(Box)({
  width: '100%',
  overflowX: 'auto',
  overflowY: 'hidden',
  '&::-webkit-scrollbar': {
    height: 12,
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: 6,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
});

const BarChartYAxis = ({ data }) => {
  const yAxisMax = Math.max(
    ...data.map((item) => item.positiveAmount),
    ...data.map((item) => Math.abs(item.negativeAmount))
  );
  const ticks = Array.from(
    { length: 5 },
    (_, index) => (2 * index - 4) * (yAxisMax / 4)
  );

  return (
    <Box
      sx={{
        width: '80px',
        height: 450,
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          layout="vertical"
          margin={{
            top: 40,
            right: 0,
            left: 10,
            bottom: 65,
          }}
        >
          <YAxis
            type="number"
            domain={[-yAxisMax, yAxisMax]}
            ticks={ticks}
            tickLine={false}
            tickFormatter={(value) => {
              const roundedValue = Math.round(value);
              const formattedValue = addCommasToNumber(Math.abs(roundedValue));
              return `${roundedValue < 0 ? '-' : ''}$${formattedValue}`;
            }}
            allowDataOverflow={true}
            tick={{
              fontSize: 12,
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const totalSales =
      payload.find((p) => p.dataKey === 'positiveAmount')?.value || 0;
    const totalExpenses = Math.abs(
      payload.find((p) => p.dataKey === 'negativeAmount')?.value || 0
    );
    const netProfitLoss = totalSales - totalExpenses;

    const formattedSales = `$${addCommasToNumber(totalSales.toFixed(2))}`;
    const formattedExpenses = `-$${addCommasToNumber(Math.abs(totalExpenses).toFixed(2))}`;
    const formattedNetProfitLoss =
      netProfitLoss < 0
        ? `-$${addCommasToNumber(Math.abs(netProfitLoss).toFixed(2))}`
        : `$${addCommasToNumber(netProfitLoss.toFixed(2))}`;

    return (
      <Paper
        elevation={3}
        style={{ padding: '10px', backgroundColor: '#ffffff' }}
      >
        <Typography variant="subtitle2" component="p">
          {label}
        </Typography>
        <Typography variant="body2" component="p">
          Total Sales: {formattedSales}
        </Typography>
        <Typography variant="body2" component="p">
          Total Expenses: {formattedExpenses}
        </Typography>
        <Typography
          variant="body2"
          component="p"
          style={{ fontWeight: 'bold' }}
        >
          Net Profit/Loss: {formattedNetProfitLoss}
        </Typography>
      </Paper>
    );
  }

  return null;
};

const BarChartBars = ({ data }) => {
  const yAxisMax = Math.max(
    ...data.map((item) => item.positiveAmount),
    ...data.map((item) => Math.abs(item.negativeAmount))
  );

  const ticks = Array.from(
    { length: 5 },
    (_, index) => (2 * index - 4) * (yAxisMax / 4)
  );

  return (
    <Box sx={{ height: 400 }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 16,
            right: 30,
            left: 0,
            bottom: 5,
          }}
          barCategoryGap="20%"
        >
          <CartesianGrid strokeDasharray="0" vertical={false} />
          <ReferenceLine y={0} stroke="#000" />
          <XAxis dataKey="name" />
          <YAxis
            type="number"
            domain={[-yAxisMax, yAxisMax]}
            ticks={ticks}
            hide
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="positiveAmount" fill="green" radius={[5, 5, 0, 0]} />
          <Bar dataKey="negativeAmount" fill="red" radius={[5, 5, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

const BarChartRecharts = ({
  transactions,
  timePeriod,
  selectedWeeks,
  selectedMonths,
  selectedQuarters,
  selectedYears,
  startDate,
  endDate,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (transactions.length > 0) {
      let filteredTransactions = [];

      const dateKeyFuncs = {
        week: (date) => {
          const weekRange = selectedWeeks.find((week) => {
            const [start, end] = week.split(' - ');
            const startDate = dayjs(`${start}, ${end.split(', ')[1]}`).startOf(
              'day'
            );
            const endDate = dayjs(end).endOf('day');
            return date.isBetween(startDate, endDate, null, '[]');
          });
          if (weekRange) {
            const [start] = weekRange.split(' - ');
            const startDate = dayjs(`${start}, ${weekRange.split(', ')[1]}`);
            return startDate.format('MM-DD-YY');
          }
          return date.format('MM-DD-YY');
        },
        month: (date) => date.format('YYYY-MM'),
        quarter: (date) => `${date.format('YYYY')}-Q${date.quarter()}`,
        year: (date) => date.format('YYYY'),
        all: () =>
          `${dayjs(startDate).format('MMM DD, YYYY')} - ${dayjs(endDate).format('MMM DD, YYYY')}`,
      };

      const dateKeyFunc = dateKeyFuncs[timePeriod] || (() => '');

      switch (timePeriod) {
        case 'week':
          const weekData = selectedWeeks.map((week) => {
            const [start, end] = week.split(' - ');
            const startDate = dayjs(`${start}, ${end.split(', ')[1]}`).startOf(
              'day'
            );
            const endDate = dayjs(end).endOf('day');

            const weeklyTransactions = transactions.filter((transaction) => {
              const transactionDate = dayjs(
                transaction.authorized_date
              ).local();
              return transactionDate.isBetween(startDate, endDate, null, '[]');
            });

            const aggregatedIncome = weeklyTransactions
              .filter((transaction) => parseFloat(transaction.amount) > 0)
              .reduce(
                (sum, transaction) => sum + parseFloat(transaction.amount),
                0
              );

            const aggregatedExpense = weeklyTransactions
              .filter((transaction) => parseFloat(transaction.amount) < 0)
              .reduce(
                (sum, transaction) => sum + parseFloat(transaction.amount),
                0
              );

            return {
              name: dateKeyFunc(startDate),
              positiveAmount: aggregatedIncome,
              negativeAmount: aggregatedExpense,
            };
          });

          setData(weekData);
          return;
        case 'month':
          filteredTransactions = transactions.filter((transaction) => {
            const transactionDate = dayjs(transaction.authorized_date)
              .local()
              .format('MMMM YYYY');
            return selectedMonths.includes(transactionDate);
          });
          break;
        case 'quarter':
          filteredTransactions = transactions.filter((transaction) => {
            const date = dayjs(transaction.authorized_date).local();
            const quarter = `Q${date.quarter()} ${date.year()}`;
            return selectedQuarters.includes(quarter);
          });
          break;
        case 'year':
          filteredTransactions = transactions.filter((transaction) => {
            const transactionDate = dayjs(transaction.authorized_date)
              .local()
              .format('YYYY');
            return selectedYears.includes(transactionDate);
          });
          break;
        case 'all':
          filteredTransactions = transactions.filter((transaction) => {
            const transactionDate = dayjs(transaction.authorized_date).local();
            const start = dayjs(startDate).startOf('day');
            const end = dayjs(endDate).endOf('day');
            return transactionDate.isBetween(start, end, null, '[]');
          });

          const aggregatedIncome = filteredTransactions
            .filter((transaction) => parseFloat(transaction.amount) > 0)
            .reduce(
              (sum, transaction) => sum + parseFloat(transaction.amount),
              0
            );

          const aggregatedExpense = filteredTransactions
            .filter((transaction) => parseFloat(transaction.amount) < 0)
            .reduce(
              (sum, transaction) => sum + parseFloat(transaction.amount),
              0
            );

          setData([
            {
              name: dateKeyFunc(),
              positiveAmount: aggregatedIncome,
              negativeAmount: aggregatedExpense,
            },
          ]);
          return;
        default:
          filteredTransactions = transactions;
          break;
      }

      const aggregatedData = filteredTransactions.reduce((acc, transaction) => {
        const date = dayjs(transaction.authorized_date).local();
        const key = dateKeyFunc(date);
        const amount = parseFloat(transaction.amount);
        if (!acc[key]) {
          acc[key] = { positiveAmount: 0, negativeAmount: 0 };
        }
        if (amount > 0) {
          acc[key].positiveAmount += amount;
        } else {
          acc[key].negativeAmount += amount;
        }
        return acc;
      }, {});

      const transformedData = Object.keys(aggregatedData)
        .map((key) => ({
          name: key,
          positiveAmount: aggregatedData[key].positiveAmount,
          negativeAmount: aggregatedData[key].negativeAmount,
        }))
        .sort((a, b) => {
          const parseDate = (name) => {
            if (name.includes(' - ')) {
              const [start] = name.split(' - ');
              return dayjs(`${start}, ${name.split(', ')[1]}`);
            } else if (/^\d{2}\/\d{4}$/.test(name)) {
              const [month, year] = name.split('/');
              return dayjs(`${year}-${month}-01`);
            } else if (/^\d{4}-Q\d$/.test(name)) {
              const [year, quarter] = name.split('-Q');
              return dayjs(`${year}-01-01`).quarter(parseInt(quarter));
            } else if (/^\d{4}$/.test(name)) {
              return dayjs(`${name}-01-01`);
            }
            return dayjs(name);
          };

          const dateA = parseDate(a.name);
          const dateB = parseDate(b.name);

          return dateA.diff(dateB);
        });

      setData(transformedData);
    }
  }, [
    transactions,
    timePeriod,
    selectedWeeks,
    selectedMonths,
    selectedQuarters,
    selectedYears,
    startDate,
    endDate,
  ]);

  const hasSalesOrExpenses = useMemo(() => {
    return data.some(
      (item) => item.positiveAmount > 0 || item.negativeAmount < 0
    );
  }, [data]);

  if (!hasSalesOrExpenses || data.length === 0) {
    return (
      <ResponsiveContainer
        width="100%"
        height="200px"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text
          x={200}
          y={100}
          textAnchor="middle"
          verticalAnchor="middle"
          style={{ fontSize: '16px', color: colors.lightGray3 }}
        >
          No data available for this period
        </Text>
      </ResponsiveContainer>
    );
  }

  return (
    <Container>
      <Box display="flex" alignItems="center">
        <BarChartYAxis data={data} />
        <ScrollContainer>
          <Box
            sx={{
              width: data.length > 7 ? `${data.length * 100}px` : '100%',
            }}
          >
            <BarChartBars data={data} />
          </Box>
        </ScrollContainer>
      </Box>
      <Box display="flex" justifyContent="center" mt={2}>
        <Box display="flex" alignItems="center" mr={2}>
          <Box
            sx={{
              width: '20px',
              height: '20px',
              backgroundColor: 'green',
              mr: 1,
            }}
          ></Box>
          <span>Income</span>
        </Box>
        <Box display="flex" alignItems="center">
          <Box
            sx={{
              width: '20px',
              height: '20px',
              backgroundColor: 'red',
              mr: 1,
            }}
          ></Box>
          <span>Expenses</span>
        </Box>
      </Box>
    </Container>
  );
};

export default BarChartRecharts;
