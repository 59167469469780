import { styled } from '@mui/material/styles';
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridToolbarExport,
} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { colors } from '../../themes/theme';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { tooltipClasses } from '@mui/material/Tooltip';
import ShareIcon from '@mui/icons-material/Share';

export const StyledSelect = styled(Select)({
  flexShrink: 0,
  maxWidth: '95px',
  fontSize: '14px',
  color: 'inherit',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSelect-icon': {
    color: 'inherit',
  },
});

export const CustomTabs = styled(Tabs)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    width: 'fit-content',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTabs-flexContainer': {
    gap: theme.spacing(0),
    [theme.breakpoints.up('xs')]: {
      gap: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(4),
    },
  },
  '& .MuiTab-root': {
    minWidth: 'auto',
    width: '100%',
    minHeight: 'auto',
    height: 36,
    borderRadius: 50,
    color: 'white',
    textTransform: 'none',
    backgroundColor: '#1360EF',
    paddingRight: '10px',
    [theme.breakpoints.down('xs')]: {
      width: 60,
    },
    [theme.breakpoints.up('sm')]: {
      width: 70,
    },
    [theme.breakpoints.up('md')]: {
      width: 80,
    },
    [theme.breakpoints.up('lg')]: {
      width: 90,
    },
    [theme.breakpoints.up('xl')]: {
      width: 120,
    },
  },
  '& .MuiTab-root.Mui-selected': {
    backgroundColor: colors.secondary,
    color: colors.white,
  },
  '& .MuiTabs-scrollableX': {
    width: 'calc(100vw - 158px)',
  },
}));

export const ItemBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  border: 1,
  borderRadius: 10,
  boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.2)',
  width: '20%',
  height: '10vh',
  paddingLeft: '30px',
});

export const ValueTypography = styled(Typography)(({ theme, color }) => ({
  fontWeight: 'bold',
  color: color || colors.black2,
  fontSize: '2.8rem',
  lineHeight: 1,
  mb: 0.5,
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.4rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.8rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '2.2rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '2.5rem',
  },
}));

export const LabelTypography = styled(Typography)(
  ({ theme, color, fw, mt }) => ({
    color: color || colors.black,
    fontSize: '1.25rem',
    lineHeight: 1,
    mb: 0.5,
    fontWeight: fw || 'normal',
    marginTop: mt,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.875rem',
    },
  })
);

export const ItemFieldset = styled('fieldset')({
  border: `1px solid ${colors.gray}`,
  borderRadius: 10,
  padding: '20px',
});

export const ItemLegend = styled('legend')(({ color }) => ({
  fontWeight: 'bold',
  fontSize: '1.1rem',
  padding: '0 10px',
  color: color || colors.black,
  textTransform: 'uppercase',
}));

export const StyledIconButton = styled(IconButton)(({ visibility }) => ({
  visibility: visibility ? 'visible' : 'hidden',
  mt: 1,
  borderRadius: '50%',
  backgroundColor: colors.black,
  border: `1px solid ${colors.gray}`,
  color: colors.white,
  '&:hover': {
    backgroundColor: colors.black2,
  },
  width: '30px',
  height: '30px',
}));

export const SDataGrid = styled(DataGrid)({
  '&.MuiDataGrid-root': {
    border: 'none',
  },
  '& .MuiButtonBase-root.MuiButton-root.MuiButton-textPrimary.MuiButton-sizeSmall':
    {
      backgroundColor: colors.secondary,
      color: 'white',
      borderRadius: '25px',
      padding: '6px 15px',
    },
  '& .MuiDataGrid-columnHeader': {
    color: colors.black,
    fontWeight: 'bolder',
    textTransform: 'uppercase',
    paddingLeft: '20px',
  },
  [`.MuiDataGrid-cell`]: {
    color: colors.black,
    backgroundColor: colors.white,
    borderColor: 'primary.light',
    paddingLeft: '20px',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: `rgb(126,10,15, 0.1)`,
    color: colors.error,
  },
  [`.MuiDataGrid-main`]: {
    backgroundColor: colors.light,
    borderRadius: '8px 8px 0px 0px',
    border: `1px solid ${colors.lightGray}`,
  },
  [`.MuiDataGrid-footerContainer`]: {
    backgroundColor: colors.lightGray,
    borderRadius: '0px 0px 8px 8px',
  },
  [`.MuiDataGrid-headerContainer`]: {
    backgroundColor: colors.lightGray,
    marginBottom: '10px',
  },
  [`.MuiDataGrid-actionsCell`]: {
    color: colors.black,
  },
  [`.MuiDataGrid-main `]: {
    marginTop: '15px',
  },
});

export const SButton = styled(Button)({
  backgroundColor: colors.black2,
  textTransform: 'none',
  borderRadius: '25px',
  fontSize: '12px',
  padding: '6px 15px',
  color: colors.white,
  '&:hover': {
    backgroundColor: colors.black,
  },
});

export const StyledGridToolbarExport = styled(GridToolbarExport)({
  textTransform: 'none',
});

export const DGExportButton = (props) => (
  <Tooltip title="Export Transactions" placement="top" arrow>
    <StyledGridToolbarExport {...props} startIcon={<ShareIcon />} />
  </Tooltip>
);

export const SGridToolbarQuickFilter = styled(GridToolbarQuickFilter)(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: '25px',
    padding: theme.spacing(1, 2),
    height: '45px',
    width: '100%',
    border: 'none',
    '&:focus-within': {
      boxShadow: theme.shadows[2],
    },
    '& .MuiInputBase-input': {
      fontSize: theme.typography.body1.fontSize,
      color: theme.palette.text.primary,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.action.active,
      marginRight: theme.spacing(1),
    },
  })
);

export const SFormControl = styled(FormControl)({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.black,
    borderRadius: '25px',
  },
  '& .MuiInputLabel-root': {
    color: colors.black,
  },
  '& .MuiSelect-select': {
    color: colors.black,
  },
  margin: 8,
  minWidth: 120,
});

export const SInputLabel = styled(InputLabel)({
  color: colors.black,
});

export const SSelect = styled(Select)({
  '&:before': {
    borderColor: colors.black,
  },
  '&:after': {
    borderColor: colors.black,
  },
});

export const SMenuItem = styled(MenuItem)({
  '&.Mui-selected': {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
});

export const HTRTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: '12px',
  '.MuiTabs-indicator': {
    display: 'none',
  },
  [theme.breakpoints.up('xs')]: {
    minHeight: '10px',
    height: '25px',
  },
  [theme.breakpoints.up('sm')]: {
    minHeight: '20px',
    height: '25px',
  },
  [theme.breakpoints.up('md')]: {
    minHeight: '20px',
    height: '25px',
  },
  [theme.breakpoints.up('lg')]: {
    minHeight: '25px',
    height: '30px',
  },
  [theme.breakpoints.up('xl')]: {
    minHeight: '30px',
    height: '35px,',
  },
}));

export const HTRTab = styled(Tab)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  height: '30px',
  marginTop: '-2px',
  '.MuiSvgIcon-root': {
    fontSize: '1.1rem',
    marginTop: '5px',
  },
  [theme.breakpoints.up('xs')]: {
    fontSize: '0.7rem',
    width: '110px',
    minHeight: '20px',
    height: '30px',
    gap: '5px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.7rem',
    width: '150px',
    minHeight: '20px',
    height: '30px',
    gap: '10px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '0.9rem',
    width: '220px',
    minHeight: '20px',
    height: '30px',
    gap: '13px',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '0.9rem',
    width: '300px',
    minHeight: '25px',
    height: '35px',
    gap: '16px',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.0rem',
    width: '500px',
    minHeight: '30px',
    height: '35px',
    gap: '25px',
  },
  color: colors.black2,
  '&.Mui-selected': {
    backgroundColor: colors.black2,
    color: 'white',
    textDecoration: 'none',
  },
  '&.MuiTab-root': {
    textTransform: 'none',
  },
  '&.Mui-selected:hover': {
    textDecoration: 'none',
  },
}));

export const CustomGridToolbarContainer = styled(GridToolbarContainer)({
  display: 'flex',
  justifyContent: 'center',
});

export const IndicatorBox = styled(Box)(({ theme, borderRadiusProp }) => ({
  marginLeft: 'auto',
  marginRight: '80px',
  color: colors.darkGray2,
  textAlign: 'center',
  borderRadius: borderRadiusProp || 50,
  padding: '4px 25px',
  display: 'inline-block',
  boxShadow: `0 1px 1px 0 ${colors.darkGray2}`,
  backgroundColor: colors.white,
}));

export const CategoryInfoTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '15px',
    maxWidth: '200px',
    padding: '10px 15px',
  },
}));
