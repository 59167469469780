import React from 'react';
import {
  Box,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Typography,
  Link,
} from '@mui/material';
import PlaidLink from '../features/PlaidLink/PlaidLink';
import { LinkButton } from '../features/PlaidLink/style.js';
import { colors } from '../../themes/theme';

const LinkBankAccountModal = ({
  open,
  handleClose,
  loadingData,
  setLoadingData,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          padding: 2,
          borderRadius: '12px',
          backgroundColor: colors.white,
        },
      }}
    >
      <DialogTitle
        sx={{ color: colors.black, padding: '16px 24px', textAlign: 'center' }}
      >
        <Typography variant="h4">Link Your Bank Account</Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: '24px', textAlign: 'center' }}>
        <Typography variant="body1" sx={{ marginBottom: '16px' }}>
          Securely connect with your bank accounts using{' '}
          <Link
            href="https://plaid.com"
            target="_blank"
            rel="noopener"
            sx={{
              color: colors.primary,
              textDecoration: 'underline',
            }}
          >
            Plaid
          </Link>
          . <br />
          <Link
            href="https://onebitapp.com"
            target="_blank"
            rel="noopener"
            sx={{
              color: colors.primary,
              textDecoration: 'underline',
            }}
          >
            ONEBIT
          </Link>{' '}
          leverages Plaid to provide insights into your business's expenses and
          transactions.
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: '30px' }}>
          By linking your accounts, you can gain a better understanding of your
          financial health and make more informed business decisions.
        </Typography>
        {loadingData ? (
          <CircularProgress sx={{ color: colors.primary }} />
        ) : (
          <Box sx={{ marginTop: '16px' }}>
            <PlaidLink
              loadingData={loadingData}
              setLoadingData={setLoadingData}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: '16px' }}>
        <Tooltip title="Cancel" placement="top" arrow>
          <LinkButton
            onClick={handleClose}
            sx={{
              borderRadius: '20px',
              backgroundColor: colors.primary,
              color: colors.white,
              padding: '8px 24px',
            }}
          >
            Cancel
          </LinkButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default LinkBankAccountModal;
